@import "../../../../assets/scss/variables.scss";

.card {
    width: 60%;
    background-color: $background-white-color;
    border-radius: 8px;
}

.cardTitle {
    font-size: $font-20;
    font-weight: 400;
    letter-spacing: -1.5px;
}

.subTitle{
    font-size: $font-12;
    font-weight: 500;
}


.boldTitle {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    line-height: 4px;
    white-space: nowrap;
}


.boldTitleLong {
    white-space: nowrap; 
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    width: 150px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.dropDownButton {
    background-color: #FFF;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    outline: none;
    border: none;
    border-radius: 4px;
}
