@import "../../assets//scss/variables.scss";

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: $backgroundColor;
}

.expandSideBar {
  width: 15%;
  transition: width 0.1s;
}
.collapseSideBar {
  width: 5%;
  transition: width 0.1s;
}
.expandPlayground {
  width: 85%;
  transition: width 0.1s;
}
.collapsePlayground {
  width: 95%;
  transition: width 0.1s;
}

@media screen and (max-width: 1200px) {
  .expandPlayground {
    width: 100%;
    min-height: calc(100vh - 65px);
  }
  .collapsePlayground {
    width: 100%;
    min-height: calc(100vh - 65px);
  }
}
