@import "../../../../assets/scss/variables.scss";

.card {
    position: relative;
}

.cardHeader {
    box-shadow: 0 20px 20px 0 rgba(221, 221, 221, 0.8);
    border-radius: 8px; 
    background-color: $background-white-color;
    width: 52vw;
    z-index:  1;
    position: absolute;
}

.header {
    color: $primary-color;
    font-size: $font-18;
    font-weight: 600;
}

.name {
    height: 60px;
}

.cardBody {
    width:  50vw;
    height: 40vh;
    background-color: $background-white-color;
    border-radius: 8px;
    overflow: scroll;
    padding-top: 120px;
    
}

.duration {
    width: 500px;
}


.tableRow {
    height: 30%;
    font-size: $font-12;
}

.bodyClassName {
    max-height: 50vh;
    display: block;
    overflow: auto;
  }
  