@import "../../../../../assets/scss/variables.scss";

* {
  font-family: "Poppins" sans-serif;
}

.content {
  padding: 20px 20px;
}

.responseContainer {
  background-color: $backgroundColor;
  width: 640px;
  height: 90%;
  padding: 50px;
  border-radius: 6px;
  font-size: 12px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.headingText {
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.response {
  max-width: 500px;
  max-height: 300px;
}

.copyButton {
  position: fixed;
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  border: none;
  background-color: #0075f3;
  border-radius: 4px;
  cursor: pointer;
}
