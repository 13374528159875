@import "../../../assets/scss/variables.scss";

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.overallheadingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.descText {
  font-size: $font-18;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.tableHeaderWrapper {
  width: 100%;
  overflow-x: auto;
  background-color: $inputBackgroundColor;
}

.productName {
  width: 300px;
  padding: 20px;
}

.billable_transaction {
  padding: 20px;
  text-align: center;
}

.success,
.failure {
  padding: 20px;
  text-align: center;
}

.tableHeader {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $secondaryColor;
  border-bottom: 0;
}

.tableBody {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
}

.value {
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.labelText {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.infoIcon {
  fill: $secondaryColor;
}

.InfoIconBack {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: default;
}

.InfoIconBack:hover {
  background: $backgroundColor;

  .infoIcon {
    fill: $secondaryColor;
  }
}

.container {
  background-color: $backgroundColor;
  height: 100%;
}


.card {
  width: 100%;
  height: 100%;
  background-color: $background-white-color;
  border-radius: 10px;
}

.cardHeader {
  font-size: $font-18;
  font-weight: 600;
  color: $primary-color;
}

.dropDownHeader {
  font-weight: 500;
  font-size: 12px;
  line-height: 0px;
}

.switch__container {
  margin: 7px auto;
  width: 35px;
  cursor: pointer;
}

.switch {
  visibility: hidden;
  position: absolute;
  border: none;
}

.switch+label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch__shadow+label {
  padding: 2px;
  width: 35px;
  height: 20px;
  background-color: $ultra-lite-green;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
  border-radius: 18px;
}

.switch__shadow+label:before,
.switch__shadow+label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

.switch__shadow+label:before {
  right: 1px;
  background-color: rgba(144, 168, 190, 0.2);
  border-radius: 18px;
  transition: background 0.4s;
}

.switch__shadow+label:after {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  background-color: $normal-green-color;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch__shadow:disabled+label {
  background-color: $backgroundColor;
}

.switch__shadow:disabled+label:after,
.switch__shadow:disabled+label:before {
  box-shadow: none;
}

.switch__shadow:checked+label:before {
  border: 0;
  background-color: $ultra-lite-green;
}

.switch__shadow:checked+label:after {
  transform: translateX(15px);
}

.switch_text {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.switch_color_active {
  color: $normal-green-color;
}

.switch__shadow:disabled+label:before {
  background: rgba($ultra-lite-green, 0.4);
  cursor: no-drop;
}

.switch__shadow:disabled+label:after {
  cursor: no-drop;
}

.insightCard {
  width: 100%;
  height: 242px;
  border-radius: 8px;
  background-color: $white;

  h3 {
    font-weight: 600;
  }

  h6 {
    font-weight: 500;
  }

  p {
    font-size: $font-12;
  }
}

// canvas {
//   width: 95% !important;
//   height: 75% !important;
// }

.warningBar {
  background-color: $warning-red;
  height: auto;
  color: red;
  width: 100%;
  font-size: 12px;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.danger {
  color: $danger !important;
}

.success {
  color: $normal-green-color !important;
}

.barContainer {
  padding: 5px;
  position: relative;
  height: 350px;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 1;
}
.legendPointerPrev {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 4px solid #008056;
}

.legendPointerCurr {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 4px solid #0075f3;
}

.bodyClassName {
  max-height: 24vh;
  display: block;
  overflow: auto;
}

.formLabel {
  padding: 0;
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-14;
  line-height: 15px;
  color: $black-color;
}

.infoText {
  padding: 10px;
  background-color: white;
  border: 0.5px solid #000000;
  border-radius: 5px;
  font-size: 12px;
  position: absolute;
  left: 25vw;
  @media (max-height: 900px) {
    left: 28vw;
  }
  top: 75px;
  animation: fade 3s linear;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  10% , 90%{ opacity: 1 }
}


.chartLegend {
  border-radius: 10px;
  width: 70px;
  height: 4px;
}

.chartLegendText {
  font-size: 14px;
}

.product_name {
  height: 65px;
}