$backgroundColor: #f7f7f7;
$linkHoverBackground: #e2f3ff;
$primaryColor: #202020;
$secondaryColor: #747474;
$accentColor: #0075f3;
$accentHoverColor: #004f9f;
$white: #fff;
$warningBackground: #fffde0;
$inputBorderColor: rgba(112, 112, 112, 0.5);
$inputBackgroundColor: #ffffff;
$cardHeaderColor: #dfdfdf;

$error: #ff005c;
$errorHoverColor: #f7cfd5;
$success: #31b975;
$successHoverColor: #e3f5eb;
$warning: #ed812f;

$annimationName: slideTransition;

$CardboxShadow: 0 3px 6px 0 rgba(221, 221, 221, 0.16);

$tableHeadColor: #343434;
$tableRowTextColor: #484848;
$successProgressColor: #0f9d58;
$disableBtnTxt: #575757;
$modalTitleColor: #292929;
$modalSecondaryColor: #a3a3a3;
$grayLite: #e2e2e2;

$devDocsGray: #e0e0e0;
$devDocsDarkGray: #747474;
$devDocsBackgroundGray: #969696;
$devDocsLightBlue: #eaf7ff;
$devDocsgrayWhite: #f7f7f7;
$devDocsBlue: #24a5c6;
$devDocsButtonBlue: #0075f3;
$devDocsDarkBlue: #2d89ec;
$devDocsGreen: #008000;
$devDocsLinkColor: #4987e4;
$devDocsWhite: white;
$devdocsgradient: linear-gradient(87.01deg, #22a8c0 0%, #2d87ee 100%);

$firaSans: "Fira Sans", sans-serif;
$poppins: "Poppins";

$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;

@keyframes slideTransition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// New Dashboard revamp variables

// Colors
$background-white-color: #ffffff;
$lite-accent-color: #cbe4ff;
$normal-accent-color: #66b0ff;
$primary-color: #0075f3;
$lite-secondary-color: #ebf4fe;
$text-secondary-color: #969696;
$chip-yellow-color: #ffc922;
$lite-green-color: #0f9d58;
$normal-green-color: #008056;
$ultra-lite-green: #90f995;
$normal-blue-color: #00006e;
$black-color: #000000;
$normal-orange-color: #d36942;
$lite-orange-color: #f4a63b;
$white-color: #ffffff;
$warning-red: #ffc5c5;
$faint-purple: #c9deff;
$danger: #c80000;

$strong-orange-color: #db4437;
$border-color: #ebebeb;
$note-bg-color: #ddecfc;
$diable-color: #d9d9d9;
$note-lite-bg-color: #F2F8FE;

// Fonts Family
$outfit: "Outfit";

// Font Sizes
$d-font-30: 30px;
$d-font-24: 24px;
$d-font-22: 22px;
$d-font-20: 20px;
$d-font-18: 18px;
$d-font-16: 16px;
$d-font-14: 14px;
$d-font-12: 12px;
